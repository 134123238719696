import { FirebaseRemoteConfig } from '@capacitor-firebase/remote-config';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {
  public async initialize(): Promise<void> {
    await FirebaseRemoteConfig.fetchAndActivate();
  }

  /*
   * Used to get boolean value from remote config
   */
  public async getBoolean(key: string): Promise<boolean> {
    const { value } = await FirebaseRemoteConfig.getBoolean({
      key
    });
    return value;
  }

  /*
   * Used to get number value from remote config
   */
  public async getNumber(key: string): Promise<number> {
    const { value } = await FirebaseRemoteConfig.getNumber({
      key
    });
    return value;
  }

  /*
   * Used to get string value from remote config
   */
  public async getString(key: string): Promise<string> {
    const { value } = await FirebaseRemoteConfig.getString({
      key
    });
    return value;
  }
}
